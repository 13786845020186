
/* initialization and general styles start */
:root {
  --primary-color: rgb(237,237,246);
  --secondary-color: rgb(130, 213, 255);
  --header-color: rgb(49,52,62);
  --text-color: rgb(64,85,117);
}
  
* {
  box-sizing: border-box;
  margin: auto;
  padding: 0;
}

body {
  background-color: var(--header-color);
  /* max-width: 1200px; */
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

a {
  text-decoration: none;
}
/* initialization and general styles end */

/* header start */
header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: var(--header-color);
}

header div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0 20px;
  width: 430px;
  height: 55px;
  background-color: var(--secondary-color);
}

header div img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 2px solid var(--header-color);
}

header h1 {
  font-weight: bold;
  font-size: 38px;
  color: var(--header-color);
}

header nav {
  margin: 25px 60px 25px 30px;
}

header nav ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}

header nav ul li a {
  font-weight: lighter;
  font-size: 1.35vw;
  padding: 5px 15px;
  border-bottom: 3px solid;
  margin: 0 6px;
  color: var(--secondary-color);
}

header nav ul li a:hover {
  color: rgb(255, 255, 255);
}

.navActive {
  color: rgb(255, 255, 255);
}
/* header end */

/* hero start  */
.hero {
  background-image: url('./assets/images/bgPortfolio.jpg');
  background-color: #b9b9b9;
  height: 250px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  opacity: 0.7;
}

.hero-mini {
  height: 140px;
}

.hero div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 100px;
  width: 420px;
  height: 55px;
  background-color: var(--secondary-color);
}

.hero h2 {
  color: var(--header-color);
  font-size: 32px;
}
/* hero end */

/* about me */
#about-me img {
  display: block;
  margin: 40px auto 30px auto;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 5px solid var(--header-color);
}

/* work section start */
.main-section {
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  display: block;
  margin-top: 50px;
  text-align: center;
  margin: 30px 100px 30px 100px;
  margin-bottom: 100px;
}

.section-title {
  text-align: right;
  margin-top: 0;
  padding-right: 15px;
  min-width: 165px;
}

.section-title h2 {
  color: var(--text-color);
  font-weight: bold;
  font-size: 43px;
  margin-left: -20px;
}

.main-content {
  border-left: 3px solid var(--text-color);
  padding-left: 30px;
  flex: 1;
}

.content p {
  line-height: 1.5;
  color: var(--text-color);
}

.main-content {
  border: none;
  padding-left: 0;
}

.section-title h2 {
  text-align: center;
  border-bottom: 3px solid;
  margin: 0 auto 35px auto;
  width: 60%;

}

/* about me start */

/* work section start */
article {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.article-detail {
  width: 48%;
  min-width: 180px;
  height: 250px;
  overflow: hidden;
  margin: 5px;
  background-color: var(--header-color);
  border: 5px solid var(--secondary-color);
  position: relative;
}

article img {
  width: 400px;
  height: 400px;
  margin-top: -100px;
  opacity: 0.45;
}

article > div:first-of-type {
  width: 97%;
  min-width: 280px;
  height: 300px;
  overflow: hidden;
}

article div a > img:first-of-type {
  width: 100%;
  height: auto;
  opacity: 0.45;
}

article img:first-of-type:hover {
  opacity: 1;
}

.article-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  bottom: 0px;
  background-color: var(--header-color);
  padding-top: 3px;
  padding-left: 5px;
  padding-right: 4px;
  color: white;
  max-width: 450px;
  text-align: left;
  height: 120px;
  opacity: 0.9;
}


article h4 {
  color: rgba(224, 224, 224, 0.932);
  font-size: 17px;
  width: 100%;
}

article a {
  margin: auto 5px;
  color: rgba(224, 224, 224, 0.932);
  font-size: 15px;
}

.article-description {
  justify-content:left;
  width: 96%;
  font-size: 15px;
  color: rgba(224, 224, 224, 0.932) !important;
}

.article-description::after {
  content: "_";
  display: inline-block;
  color: white;
  animation: blink 0.5s infinite;
}

.article-base {
  margin-bottom: 5px;
}

@keyframes blink {
  0% {
      opacity: 1;
  }
  50% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
/* work section end */
 
/* resume section end */
.resume {
  margin: 30px 150px;
  margin-bottom: 70px;
  text-align: left;
}

.resume h3 {
  margin: 13px auto;
}

.resume li {
  margin-left: 20px;
}

.resume button {
  margin-bottom: 20px;
  width: 140px;
}
/* end resume section end */

/* contact form */
form {
  width: 50%;
  text-align: left;
  border-radius: 8px;
  background-color: rgb(255, 255, 255, .5);
  font-size: 16px;
  padding: 15px;
}

input {
  width: 100%;
  background-color: #fff;
  color: var(--header-color);
  padding: 10px 20px;
  margin: 5px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  cursor: pointer;
}
input:hover {
  background-color: #ffffffe8;
}

input:focus {
  border: 1px solid var(--secondary);
  box-shadow: 0 0 .25rem rgba(0, 0, 0, .5);
}

textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  box-sizing: border-box;
  color: var(--header-color);
  margin-top: 8px;
  margin-bottom: 16px;
  resize: vertical;
}

button {
  padding: 9px;
  width: 100px;
}

button:hover {
  background-color: var(--primary-color);
}

button:active {
  background-color: var(--primary-color);
  border: 3px;
}

.btn-div {
  text-align: end;
}
/* end contact form */

/* contact me section start */
.footer {
  background-color: var(--header-color);
  margin: 0;
}

.footer-menu {
  padding: 60px 0;
}
.footer-menu ul {
  display: flex;
  flex-wrap: wrap;
  justify-content:center;

  align-items: center;
  list-style: none;
}

.footer-menu ul li {
  font-weight: bolder;
  font-size: 35px;
  padding: 5px 20px;
  margin: 0 0px;
  opacity: 0.6;
}

.footer-menu ul li:hover{
  opacity: 0.9;
}

.footer-menu ul li a {
  color: var(--secondary-color);
}

.footer-menu ul li a:visited {
  color: var(--secondary-color);
}

.email a {
  font-size: 18px;
}
/* contact me section end */

/* media query 980px start */
@media screen and (max-width: 980px) {

  header {
    padding-bottom: 0;
    position: relative;
  }

  header nav ul {
    width: 100%;
    justify-content: center;
  }

  header nav ul li a {
    font-size: 14px;
    padding: 5px 0;
  }

  .hero div {
    margin-right: 30px;
  }

  .hero-mini {
    display: none;
  }

  .content {
    margin: 30px;
  }

  .footer-menu ul li a {
    font-size: 16px;
    margin: 5px;
    padding: 5px;
  }
  

}
/* media query 980px start */

/* media query 768px start */
@media screen and (max-width: 768px) {
    
  header {
    justify-content: center;
    height: 110px;
  }

  header div {
    width: 420px;
    height: 45px;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }

  header div img {
    display: none;
  }

  header h1 {
    font-size: 28px;
    margin: 0;
  }

  header nav {
    margin: 12px 30px 25px 30px;
  }

  header nav ul li a {
    font-size: 16px;
    margin: 5px;
    padding: 5px;
  }

  .hero div {
    width: 350px;
    height: 45px;
  }

  .hero div h2 {
    font-size: 24px;
  }

  .section-title h2 {
    font-size: 36px;

  
  }

  form {
    width: 70%;
  }

  /* delete break line in titles */
  .section-title br {
    content:" ";
    display: none;

  }

  .section-title br:after {
    content:" ";
  }

  #contact-me h2{
    display: none;
  }

  .footer-menu {
      padding: 15px 0;
  }

}
/* media query 768px end */

/* media query 575px start */
@media screen and (max-width: 575px) {

  .hero {
    height: 150px;
  }

  .hero div {
    width: 280px;
    height: 35px;
    margin-bottom: 10px;
    margin-right: 0;
  }

  .hero div h2 {
    font-size: 20px;
  }

  .content {
    margin: 10px;
  }

  form {
    width: 90%;
  }

  .content p {
    line-height: 1.25;
    font-size: 19px;
    text-align: justify;
    text-justify: inter-word;
  }

  .article-detail {
    width: 100%;
  }

  /* no hover on images for finger driven devices */
  article img {
    opacity: 1;
  }
  article > div:first-of-type {
    height: 250px;
  }

  article div a > img:first-of-type {
    opacity: 1;
  }

  article a {
    font-size: 15px !important;
  }
  
  .article-description {
    font-size: 15px !important;
  }

  #work {
    margin-top: 45px;
  }

  /* some space from the botton of a mobile device */
  .main-content nav {
    margin-bottom: 50px;
  }

  .article-base a {
    font-size: 13px !important;
    margin-bottom: 2px;
  }
  
  .article-base i {
    margin-right: 0px;
    margin-left: 0px;
  }

/* media query 575px end */
}

/* fix nav font size on screens over 980px */
@media screen and (min-width: 981px) {

  header nav ul li a {
    font-size: 16px;
  }

}

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css');


